import React, { Component } from "react";
import { Link } from "react-router-dom";
import VideoModal from "../../components/ModalVideo";
import client1 from "../../images/slider/client1.png";
import client2 from "../../images/slider/client2.png";
import client3 from "../../images/slider/client3.png";
import client4 from "../../images/slider/client4.png";
import hero from "../../images/aelyx/preview.png";
import hero1 from "../../images/aelyx/home1.jpeg";

class Hero extends Component {
  render() {
    return (
      <section
        id="home"
        className="wpo-hero-section-1 wpo-hero-section-2 section-padding"
        style={{
          width: "100%", // Full width of the container
          height: "100vh", // Full viewport height (100% of the screen height)
          display: "flex", // Flex container to align children (optional based on content)
          flexDirection: "column", // Align content in a column (optional)
          justifyContent: "center", // Center content vertically (optional)
          alignItems: "center", // Center content horizontally (optional)
        }}
      >
        {/* // <section id="home"style={{ fontSize: '2rem', fontWeight: 'bold', color: '#333 ' }} > */}
        <div className="container">
          <div className="row">
            <div className="col col-xs-6 col-lg-6">
              <div className="wpo-hero-section-text">
                <div className="wpo-hero-title">
                  <h2>"Empowering Your Wealth, Securing Your Future"</h2>
                </div>
                <div className="wpo-hero-subtitle">
                  <p>
                    Brief Description: Our platform is designed to simplify your
                    financial journey—seamlessly onboarding clients, executing
                    stock orders, and providing top-tier advisory services under
                    the regulatory framework in India. From agreements to
                    payments, we ensure a smooth and secure experience for
                    investors and advisors alike.
                  </p>
                </div>
              </div>
              <div className="right-vec">
                <div className="right-img">
                  <div className="r-img">
                    <img src={hero} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Hero;
