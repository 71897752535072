import React, { Component } from 'react'
import { Collapse, CardBody, Card } from 'reactstrap';
import { Link } from 'react-router-dom'




import './style.css';

const menus = [
    {
        id: 1,
        title: 'Home',
        link: '/home',
        
    },

    {
        id: 2,
        title: 'About',
        link: '/#about',
    },

{
    id: 3,
        title: 'Services',
        link: '/#services',
        
    },

    {
        id: 88,
        title: 'Contact',
        link: '/contact',
    }
    
    
]


export default class MobileMenu extends Component {

    state = {
        isMenuShow: false,
        isOpen: 0,
    }

    menuHandler = () => {
        this.setState({
            isMenuShow: !this.state.isMenuShow
        })
    }

    setIsOpen = id => () => {
        this.setState({
            isOpen: id === this.state.isOpen ? 0 : id
        })
    }

    render() {

        const { isMenuShow, isOpen } = this.state;

        const ClickHandler = () =>{
            window.scrollTo(10, 0);
         }

        return (
            <div>
                <div className={`mobileMenu ${isMenuShow ? 'show' : ''}`}>
                    <div className="menu-close">
                    <div className="clox" onClick={this.menuHandler}><i className="ti-close"></i></div>
                    </div>

                   
                <ul className="responsivemenu">
                                    <li><Link onClick={ClickHandler} to="/home#about">Home</Link></li>
                                    
                                    <li><a href='/home#about'>About</a></li>
                                   
                                    <li><a href='/home#services'>Services</a></li>    

                                    {/* <li><a href='#demo'>demo</a></li> */}
                                    
                                    <li><Link onClick={ClickHandler} to="/contact">Contact</Link></li>

                </ul>
                </div>

                <div className="showmenu" onClick={this.menuHandler}>
                    <button type="button" className="navbar-toggler open-btn">
                            <span className="icon-bar first-angle"></span>
                            <span className="icon-bar middle-angle"></span>
                            <span className="icon-bar last-angle"></span>
                    </button>
                </div>
            </div>
        )
    }
}
